const initialState = {
  slides: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
  homes: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
  contact: {
    data: {},
    isLoading: false,
    isRejected: false,
  },
  secSlides: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "HOME_FETCHING":
      return {
        ...state,
        homes: {
          data: [],
          isLoading: true,
          isRejected: false,
        },
      };
    case "HOME_SUCCESS":
      return {
        ...state,
        homes: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "HOME_REJECTED":
      return {
        ...state,
        homes: {
          isLoading: false,
          isRejected: true,
        },
      };
    case "SLIDE_FETCHING":
      return {
        ...state,
        slides: {
          isLoading: true,
          isRejected: false,
          data: [],
        },
      };
    case "SLIDE_SUCCESS":
      return {
        ...state,
        slides: {
          isLoading: false,
          isRejected: false,
          data: payload,
        },
      };
    case "SLIDE_REJECTED":
      return {
        ...state,
        slides: {
          isLoading: false,
          isRejected: true,
        },
      };
    case "SEC_SLIDE_FETCHING":
      return {
        ...state,
        secSlides: {
          isLoading: true,
          isRejected: false,
          data: [],
        },
      };
    case "SEC_SLIDE_SUCCESS":
      return {
        ...state,
        secSlides: {
          isLoading: false,
          isRejected: false,
          data: payload,
        },
      };
    case "SEC_SLIDE_REJECTED":
      return {
        ...state,
        secSlides: {
          isLoading: false,
          isRejected: true,
        },
      };
    case "CONTACT_FETCHING":
      return {
        ...state,
        contact: {
          data: {},
          isLoading: true,
          isRejected: false,
        },
      };
    case "CONTACT_SUCCESS":
      return {
        ...state,
        contact: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "CONTACT_REJECTED":
      return {
        ...state,
        contact: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

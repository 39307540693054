import React from "react";
// react component for creating beautiful carousel
import Slider from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
import PropTypes from "prop-types";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
// import { Typography } from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import { imageUrl } from "Constants";
const useStyles = makeStyles(styles);

export default function SectionCarousel({ data, slidesToShow }) {
  const classes = useStyles();
  // const history = useHistory();
  const settings = {
    autoplay: true,
    pauseOnHover: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      {/* <div className={classes.mainRaised}> */}
      {/* <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}> */}
      {/* <Card carousel> */}
      <Slider {...settings}>
        {data &&
          data.map((item) => (
            <div key={item.id}>
              <img
                src={`${imageUrl}partner/${item.logo}`}
                alt={item.alt}
                className={classes.imagePartner}
              />
              {/* <div className="slick-caption">
                <Typography variant={titleSize}>{item.title}</Typography>
                {item.subtitle && <span>{item.subtitle}</span>}
              </div> */}
            </div>
          ))}
      </Slider>
      {/* </Card> */}
      {/* </GridItem>
        </GridContainer> */}
      {/* </div> */}
    </div>
  );
}

SectionCarousel.defaultProp = {
  titleSize: "h3",
  data: [],
};

SectionCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      images: PropTypes.string.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  slidesToShow: PropTypes.number.isRequired,
  titleSize: PropTypes.oneOf(["h2", "h3", "h4", "h5", "h6", "span"]),
};

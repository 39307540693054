import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "redux/reducers";
import logger from "redux-logger";

var middlewares = (middlewares = applyMiddleware(thunk, logger));

const store = createStore(rootReducer, middlewares);

export { store };

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "redux/store";
import App from "App";
import { getProfile } from "redux/actions/auth.actions";
import { getContact } from "redux/actions/contact.action";
import { getAccessToken } from "redux/actions/auth.actions";

const token = localStorage.getItem("@token");
store.dispatch(getContact());
// check login
if (token) {
  store.dispatch(getProfile());
  store.dispatch({ type: "LOGIN_SUCCESS" });
  store.dispatch(getAccessToken());
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Oranienbaum",
    src: `
    local('Oranienbaum'),
    url("public/fonts/Oranienbaum.ttf") format('ttf')
  `,
  },
  MuiTypography: {
    variantMapping: {
      h1: "h2",
      h2: "h2",
      h3: "h2",
      h4: "h2",
      h5: "h2",
      h6: "h2",
      subtitle1: "h2",
      subtitle2: "h2",
      body1: "span",
      body2: "span",
    },
  },
  palette: {
    primary: {
      main: "rgb(183, 33, 38)",
    },
    secondary: {
      main: "rgb(122, 15, 45)",
    },
  },
});

export default responsiveFontSizes(theme);

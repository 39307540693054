import React, { useEffect, useRef, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Primary from "components/Typography/Primary";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import EditIcon from "@material-ui/icons/Edit";
import { Box, Button, Divider, FormControl } from "@material-ui/core";
import Layouts from "components/Layouts";
import { useSelector } from "react-redux";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { httpClient } from "utils/HttpClient";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();

  const [progressBarValue, setProgressBarValue] = useState(0);
  const { user, isLoading } = useSelector((state) => state.userReducer);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      intervalRef.current = setInterval(() => {
        setProgressBarValue((prev) => {
          if (prev >= 100) {
            clearInterval(intervalRef.current);
            return 100;
          } else {
            return prev + 5;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isLoading]);

  if (isLoading) {
    return (
      <Layouts title="Profile">
        <Header
          color="primary"
          brand="KARST Legal & Tax"
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <div
          className={classNames(classes.pageHeader)}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <CustomLinearProgress
              variant="determinate"
              color="info"
              value={progressBarValue}
            />
          </div>
        </div>
      </Layouts>
    );
  }

  return (
    <Layouts title="Profile">
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <Formik
              enableReinitialize
              initialValues={{
                ...user.profile,
                password: "",
                confirmPassword: "",
                token: localStorage.getItem("@token"),
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                httpClient
                  .post(
                    `/auth/update-profile?token=${localStorage.getItem(
                      "@token"
                    )}`,
                    values
                  )
                  .then(({ data }) => {
                    alert(JSON.stringify(data));
                  })
                  .catch(() => {});
                resetForm({});
              }}
              validationSchema={validationSchema}
              render={({
                submitForm,
                isSubmitting,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexWrap="wrap"
                    flexGrow={1}
                  >
                    <Primary>
                      <h3
                        style={{
                          fontFamily: "Oranienbaum",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        {t("PROFILE_HEADING_LINE")}
                      </h3>
                    </Primary>
                    <Divider />
                    <Box p={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                        variant="outlined"
                      >
                        <Field
                          component={TextField}
                          name="name"
                          label={t("PROFILE_INPUT_NAME")}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.name}
                          variant="outlined"
                        />
                      </FormControl>
                    </Box>
                    <Box p={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        variant="outlined"
                      >
                        <Field
                          component={TextField}
                          name="email"
                          label={t("PROFILE_INPUT_EMAIL")}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                          variant="outlined"
                          value={values.email}
                        />
                      </FormControl>
                    </Box>
                    <Box p={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        variant="outlined"
                      >
                        <Field
                          component={TextField}
                          name="password"
                          label={t("PROFILE_INPUT_PASSWORD")}
                          error={Boolean(errors.password && touched.password)}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="password"
                          variant="outlined"
                        />
                      </FormControl>
                    </Box>
                    <Box p={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          errors.confirmPassword && touched.confirmPassword
                        )}
                        variant="outlined"
                      >
                        <Field
                          component={TextField}
                          name="confirmPassword"
                          label={t("PROFILE_INPUT_PASSWORD_CONFIRM")}
                          error={Boolean(
                            errors.confirmPassword && touched.confirmPassword
                          )}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="password"
                          variant="outlined"
                        />
                      </FormControl>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center">
                      <Button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        variant="contained"
                        size="large"
                        className={classes.buttonForm}
                      >
                        <EditIcon />
                        {t("PROFILE_INPUT_BUTTON")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
      <Footer />
    </Layouts>
  );
}

const initialState = {
  exams: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
  examResults: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
  examScore: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "EXAM_FETCHING":
      return {
        ...state,
        exams: {
          isLoading: true,
          isRejected: false,
        },
      };
    case "EXAM_SUCCESS":
      return {
        ...state,
        exams: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "EXAM_REJECTED":
      return {
        ...state,
        exams: {
          isLoading: false,
          isRejected: true,
        },
      };
    case "EXAM_RESULT_FETCHING":
      return {
        ...state,
        examResults: {
          isLoading: true,
          isRejected: false,
        },
      };
    case "EXAM_RESULT_SUCCESS":
      return {
        ...state,
        examResults: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "EXAM_RESULT_REJECTED":
      return {
        ...state,
        examResults: {
          isLoading: false,
          isRejected: true,
        },
      };
    case "EXAM_SCORE_FETCHING":
      return {
        ...state,
        examScore: {
          isLoading: true,
          isRejected: false,
        },
      };
    case "EXAM_SCORE_SUCCESS":
      return {
        ...state,
        examScore: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "EXAM_SCORE_REJECTED":
      return {
        ...state,
        examScore: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

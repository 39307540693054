import React, { useRef, useState, useEffect } from "react";
import "assets/css/multiple-slide.css";
import Carousel from "react-elastic-carousel";
import { imageUrl } from "Constants";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./MutipleSlider.css";
import { mintGreenColor } from "assets/jss/material-kit-react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  buttonReadMore: {
    backgroundColor: "RGB(33,46,60)",
    color: "#fff",
    "&:hover": {
      backgroundColor: mintGreenColor,
    },
  },
});

export default function MutipleSlider({ items }) {
  const history = useHistory();
  const classes = useStyles();
  const carouselRef = useRef(null);
  const [totalPage, setTotalPage] = useState(3);

  const { t } = useTranslation();

  const updateDimensions = () => {
    setTotalPage(Math.ceil(items.length / getItemPerPage(window.innerWidth)));
  };
  const getItemPerPage = (w) => {
    if (w >= 550) {
      return 3;
    } else {
      return 1;
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  let resetTimeout;
  console.log(totalPage);
  return (
    <div
      style={{
        margin: 10,
      }}
    >
      <Carousel
        ref={carouselRef}
        enableAutoPlay
        autoPlaySpeed={4000}
        itemPadding={[10]}
        itemsToShow={3}
        breakPoints={[
          { width: 1, itemsToShow: 1, itemsToScroll: 1 },
          { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
          { width: 850, itemsToShow: 3, itemsToScroll: 3 },
          { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
          { width: 1450, itemsToShow: 3, itemsToScroll: 3 },
          { width: 1750, itemsToShow: 3, itemsToScroll: 3 },
        ]}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index + 1 === totalPage) {
            resetTimeout = setTimeout(() => {
              carouselRef.current.goTo(0);
            }, 1500);
          }
        }}
        pagination={false}
        showArrows={false}
        itemsToScroll={3}
        infinte
      >
        {items &&
          items.map((i) => (
            <>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  maxWidth: 500,
                  height: 350,
                }}
                flex={1}
              >
                <Box>
                  <img
                    key={i.id}
                    src={`${imageUrl}second_slide/${i.background}`}
                    // style={{
                    //   height: 230,
                    // }}
                    className="image-slide"
                  />
                </Box>
                <Box
                  style={{
                    width: "100%",
                  }}
                >
                  {i.content && (
                    <Typography variant="subtitle1" paragraph>
                      {i.content}
                    </Typography>
                  )}
                  {i.comment && (
                    <Typography
                      variant="subtitle2"
                      className="legend2"
                      style={{
                        backgroundColor: "RGB(33, 46, 60)",
                      }}
                    >
                      {i.comment}
                    </Typography>
                  )}
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      history.push(`/blog/${i.slug}`);
                    }}
                    className={classes.buttonReadMore}
                  >
                    {t("BUTTON_READ_MORE")}
                  </Button>
                </Box>
              </Box>
            </>
          ))}
      </Carousel>
    </div>
  );
}

MutipleSlider.defaultProp = {
  items: [],
};

MutipleSlider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      background: PropTypes.string.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

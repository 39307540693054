import { Container, LinearProgress, makeStyles } from "@material-ui/core";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Layouts from "components/Layouts";
import React, { useEffect } from "react";
import styles from "assets/jss/material-kit-react/views/homePage.js";
import Footer from "components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy } from "redux/actions/privacyPlolicy.action";
import classNames from "classnames";
import "./PrivacyPolicyPage.css";

const useStyles = makeStyles({
  ...styles,
  root: {
    "root-Link": {
      textDecoration: "none",
    },
  },
});

export default function PrivacyPolicyPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const dispatch = useDispatch();
  const { privacyPolicy } = useSelector((state) => state.privacyPolicyReducer);
  // const _node = useRef();

  useEffect(() => {
    dispatch(getPrivacyPolicy());
    return () => {};
  }, []);

  if (privacyPolicy.isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <div className={classes.root}>
      <Layouts
        title={"Legal Notice"}
        tag={
          privacyPolicy.data &&
          privacyPolicy.data.meta &&
          privacyPolicy.data.meta.tags
        }
      >
        <Header
          color="primary"
          brand="KARST Legal & Tax"
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 500,
            color: "white",
          }}
          {...rest}
        />
        <Container maxWidth="xl" disableGutters>
          <div className={classNames(classes.main, classes.defaultBoxShadow)}>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 30,
              }}
              dangerouslySetInnerHTML={{
                __html:
                  privacyPolicy.data &&
                  privacyPolicy.data.privacy_policy.content,
              }}
            />
          </div>
        </Container>
        <Footer />
      </Layouts>
    </div>
  );
}

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";
import { imageUrl } from "Constants";
import "assets/css/slider.css";

export default function Slide({
  items,
  height,
  centerMode,
  setWidth,
  secondary,
}) {
  return (
    <Carousel
      showArrows={true}
      onClickItem={() => {}}
      centerMode={centerMode}
      showStatus={false}
      showThumbs={false}
      centerSlidePercentage={50}
      autoPlay
      infiniteLoop
    >
      {items &&
        items.map((i) => (
          <div
            key={i.id}
            style={{
              height: height,
            }}
          >
            <img
              src={`${imageUrl}slide/${i.image}`}
              style={{
                maxHeight: 550,
                minHeight: 300,
                width: setWidth,
                maxWidth: "100%",
                objectFit: "fill",
              }}
              className={secondary ? "img-slide-sec" : "img-slide"}
            />
            <div className="contentSlider">
              <div className="bgText">
                <div>
                  <h1 className={"titleText"}>{i.title}</h1>
                </div>
                <div>
                  <h5 className={"subTitleText"}>{i.description}</h5>
                </div>
              </div>
            </div>
          </div>
        ))}
    </Carousel>
  );
}

Slide.defaultProp = {
  items: [],
  height: 500,
  setWidth: "100%",
  centerMode: false,
  fixed: false,
  secondary: false,
};

Slide.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      images: PropTypes.string.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  height: PropTypes.number.isRequired,
  centerMode: PropTypes.bool.isRequired,
  setWidth: PropTypes.number.isRequired,
  secondary: PropTypes.bool.isRequired,
};

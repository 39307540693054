import { primaryColor } from "assets/jss/material-kit-react";
import { mintGreenColor } from "assets/jss/material-kit-react";
import {
  container,
  deepBlueColor,
  buttonForm,
} from "assets/jss/material-kit-react.js";

const componentsStyle = {
  container,
  buttonForm,
  section: {
    // padding: "70px 0",
    // padding: "5px 50px 10px",
    // marginBottom: 50,
    marginTop: 50,
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left",
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
    color: deepBlueColor,
    margin: 15,
  },
  slideHeader: {
    marginTop: 40,
    marginBottom: 40,
  },
  linkStyle: {
    color: primaryColor,
    "&:hover": {
      color: mintGreenColor,
    },
  },
};

export default componentsStyle;

const sliderStyle = {
  textBold: {
    fontWeight: 700,
    color: "rgb(133,240,210)",
    textShadow: "3px 3px 5px rgb(33,46,60)",
    // fontSize: "3vw",
    fontFamily: "Oranienbaum !important",
  },
  textP: {
    margin: "0 0 11.5px",
    textShadow: "2px 2px 4px #000000",
    color: "rgb(133,240,210)",
    fontFamily: "Oranienbaum !important",
    // fontSize: "1.5vw",
  },
  contentSlider: {
    width: "50%",
  },
};

export default sliderStyle;

const initialState = {
  result: null,
  isFetching: false,
  error: false,
  progressValue: 0,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case "LOGIN_FETCHING":
      return {
        ...state,
        isFetching: true,
        error: false,
        result: null,
        progressValue: Math.floor(Math.random() * 101),
      };
    case "LOGIN_FAILED":
      return { ...state, isFetching: false, error: true, progressValue: 0 };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        progressValue: 0,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

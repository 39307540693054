const initialState = {
  user: null,
  isLoading: false,
  isRejected: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "USER_FETCHING":
      return {
        ...state,
        isLoading: true,
        isRejected: false,
      };
    case "USER_SUCCESS":
      return {
        ...state,
        user: payload,
        isLoading: false,
        isRejected: false,
      };
    case "USER_REJECTED":
      return { ...state, isLoading: false, isRejected: true };
    default:
      return state;
  }
};

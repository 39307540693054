/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
// import Card from "components/Card/Card";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Layouts from "components/Layouts";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-kit-react/views/mydocumentPage.js";
// import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getExamResult } from "redux/actions/exam.actions";
import axios from "axios";
import { httpClient } from "utils/HttpClient";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Check from "@material-ui/icons/Check";
import { green, red } from "@material-ui/core/colors";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { getExamScore } from "redux/actions/exam.actions";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
// const useStyles = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  ...styles,
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
}));

export default function ExamResultPage(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);

  const { examResults, examScore } = useSelector((state) => state.examReducer);

  useEffect(() => {
    dispatch(getExamResult());
    dispatch(getExamScore());
    return () => {};
  }, []);

  const [progressBarValue, setProgressBarValue] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (examResults.isLoading) {
      intervalRef.current = setInterval(() => {
        setProgressBarValue((prev) => {
          if (prev >= 100) {
            clearInterval(intervalRef.current);
            return 100;
          } else {
            return prev + 5;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [examResults.isLoading]);

  return (
    <Layouts title="Exam Result">
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 500,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="md" style={{ marginTop: 110 }}>
        <Card plain className={classes.container}>
          <CardHeader title="Congratulations" />
          <CardContent>
            {examResults.isLoading && (
              <CustomLinearProgress
                variant="determinate"
                color="info"
                value={progressBarValue}
              />
            )}
            {examResults.data && examScore.data && (
              <Box>
                <Box
                  p={5}
                  border={1}
                  flexGrow={1}
                  textAlign="center"
                  style={{ verticalAlign: "middle" }}
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    style={{ verticalAlign: "middle", display: "inline-flex" }}
                  >
                    <EmojiEventsIcon style={{ fontSize: 40 }} /> Your Score :{" "}
                    {examScore.data.calculate_point}/{examResults.data.length}{" "}
                    Point
                  </Typography>
                </Box>
              </Box>
            )}

            {examResults.data &&
              examResults.data.map((e) => (
                <>
                  <Card key={e.id} variant="outlined">
                    <CardContent>
                      <Typography
                        color="textSecondary"
                        variant="h5"
                        component="h2"
                      >
                        {e.question}
                      </Typography>
                      <Divider />
                      {e.exam_answer_students.map((s) => (
                        <>
                          <Typography
                            style={{
                              color:
                                s.exam_choice.make_correct === 1
                                  ? green[600]
                                  : red[600],
                              verticalAlign: "middle",
                            }}
                            key={s.id}
                            variant="subtitle1"
                            component="p"
                            // className={classes.wrapIcon}
                          >
                            {s.details}{" "}
                            {e.type === "writing" &&
                              "(Please wait to feedbalck.)"}
                            {e.type !== "writing" && s.exam_choice.description}{" "}
                            {s.exam_choice.make_correct === 1 && " is Correct"}
                          </Typography>
                        </>
                      ))}
                    </CardContent>
                  </Card>
                  <br />
                </>
              ))}
          </CardContent>
        </Card>
      </Container>
      <Clearfix />
    </Layouts>
  );
}

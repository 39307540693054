const initialState = {
  searchResult: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
  selectSearchContent: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SEARCH_CONTENT_SUCCESS":
      return {
        ...state,
        selectSearchContent: payload,
      };
    case "SEARCH_FETCHING":
      return {
        ...state,
        searchResult: {
          isLoading: true,
          isRejected: false,
          data: [],
        },
      };
    case "SEARCH_SUCCESS":
      return {
        ...state,
        searchResult: {
          isLoading: false,
          isRejected: false,
          data: payload,
        },
      };
    case "SEARCH_REJECTED":
      return {
        ...state,
        searchResult: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

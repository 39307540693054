import { httpClient } from "utils/HttpClient";

export const getPrivacyPolicy = () => {
  return (dispatch) => {
    dispatch({
      type: "PRIVACYPOLICY_FETCHING",
    });

    return httpClient
      .get(`/frontend/privacy-policy`)
      .then(({ data }) => {
        dispatch({
          type: "PRIVACYPOLICY_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "PRIVACYPOLICY_REJECTED" }));
  };
};

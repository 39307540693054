import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/entrance.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { login } from "redux/actions/auth.actions";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import classNames from "classnames";
import Layouts from "components/Layouts";

const useStyles = makeStyles(styles);

const formLoginValidate = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [progressBarValue, setProgressBarValue] = useState(0);

  const intervalRef = useRef(null);

  const { isFetching } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (isFetching) {
      intervalRef.current = setInterval(() => {
        setProgressBarValue((prev) => {
          if (prev >= 100) {
            clearInterval(intervalRef.current);
            return 100;
          } else {
            return prev + 5;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isFetching]);

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  if (isFetching) {
    return (
      <Layouts title="Login">
        <Header
          color="primary"
          brand="KARST Legal & Tax"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <div
          className={classNames(classes.pageHeader, classes[cardAnimaton])}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <CustomLinearProgress
              variant="determinate"
              color="info"
              value={progressBarValue}
            />
          </div>
        </div>
      </Layouts>
    );
  }

  return (
    <Layouts title="Login">
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Formik
                key={`PurchaseHeadForm`}
                enableReinitialize
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    dispatch(login(values, history));
                    setSubmitting(false);
                  }, 500);
                }}
                validationSchema={formLoginValidate}
              >
                {(formikProps) => {
                  const {
                    handleSubmit,
                    setFieldValue,
                    values,
                    errors,
                    touched,
                  } = formikProps;

                  return (
                    <Form noValidate className={classes.form}>
                      <Card className={classes[cardAnimaton]}>
                        <CardHeader
                          color="primary"
                          className={classes.cardHeader}
                        >
                          <h4 style={{ fontFamily: "Oranienbaum" }}>
                            {t("LOGIN_PAGE_TITLE")}
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <Field
                            component={CustomInput}
                            labelText={t("LOGIN_EMAIL_TITLE")}
                            id="email"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            error={Boolean(errors.email || touched.email)}
                            inputProps={{
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              ),
                              onChange: (e) => {
                                setFieldValue("email", e.target.value);
                              },
                              value: values.email,
                            }}
                          />
                          <Field
                            component={CustomInput}
                            labelText={t("LOGIN_PASSWORD_TITLE")}
                            id="pass"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            error={Boolean(errors.password || touched.password)}
                            inputProps={{
                              type: "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Icon className={classes.inputIconsColor}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              autoComplete: "off",
                              onChange: (e) => {
                                setFieldValue("password", e.target.value);
                              },
                              value: values.password,
                            }}
                            name="password"
                          />
                        </CardBody>
                        <CardFooter className={classes.cardFooter}>
                          <Button
                            simple
                            color="primary"
                            size="lg"
                            onClick={handleSubmit}
                          >
                            {t("LOGIN_BUTTON_TITLE")}
                          </Button>
                        </CardFooter>
                      </Card>
                    </Form>
                  );
                }}
              </Formik>
            </GridItem>
          </GridContainer>
        </div>
        {/* <Footer /> */}
      </div>
    </Layouts>
  );
}

/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import { Container, makeStyles, Box, Button } from "@material-ui/core";
import Card from "components/Card/Card";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Layouts from "components/Layouts";
import styles from "assets/jss/material-kit-react/views/mydocumentPage.js";
// import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { GraphFileBrowser } from "@microsoft/file-browser";
import { useSelector } from "react-redux";
import $ from "jquery";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import "./MyDocumentPage.css";

const useStyles = makeStyles(styles);

export default function MyDocumentPage(props) {
  const { ...rest } = props;
  const classes = useStyles();
  // const { t } = useTranslation();
  const { user } = useSelector((state) => state.userReducer);
  const { tokenData, itemId } = useSelector((state) => state.graphReducer);
  const history = useHistory();

  useEffect(() => {
    // ms-Breadcrumb-list
    // setTimeout(() => {
    //   $(".ms-Breadcrumb-list").hide();
    // }, 200);

    return () => {};
  }, [tokenData]);

  return (
    <Layouts title="My document">
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="md" style={{ marginTop: 150 }}>
        <Card plain className={classes.container}>
          <div className={classNames(classes.main, classes.defaultBoxShadow)}>
            {user && tokenData && (
              <>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mb={2}
                  alignItems="right"
                  flex={1}
                >
                  <Box>
                    <Button
                      onClick={() => {
                        history.push("/mydocument-page");
                        history.go();
                        $(".ms-Breadcrumb-list").hide();
                      }}
                      className={classes.buttonForm}
                      variant="outlined"
                    >
                      <ArrowBackIcon /> Back
                    </Button>
                  </Box>
                </Box>
                <GraphFileBrowser
                  getAuthenticationToken={() => {
                    return tokenData;
                  }}
                  itemId={itemId}
                  itemMode="all"
                  selectionMode="pick"
                />
              </>
            )}
          </div>
        </Card>
      </Container>
    </Layouts>
  );
}

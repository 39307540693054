import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import "assets/css/carousel.min.css";
import PropTypes from "prop-types";
import { Avatar, Grid, makeStyles } from "@material-ui/core";
import { imageUrl } from "Constants";
import styles from "assets/jss/material-kit-react/components/sliderStyle.js";
import "./TestimonialSlide.css";

const useStyles = makeStyles((theme) => ({
  ...styles,
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: 15,
  },
}));

export default function TestimonialSlide({
  items,
  height,
  centerMode,
  imageBg,
  // setWidth,
  // secondary,
}) {
  const classes = useStyles();

  return (
    <Carousel
      showArrows={true}
      onClickItem={() => {}}
      centerMode={centerMode}
      showStatus={false}
      showThumbs={false}
      centerSlidePercentage={50}
      autoPlay
      infiniteLoop
    >
      {items &&
        items.map((i) => (
          <div
            key={i.id}
            className="contentSlide"
            style={{
              height: height,
              backgroundImage: `url(${imageBg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {/* <img
              src={require("assets/img/bg-testimonial.jpg").default}
              style={{
                width: setWidth,
                maxWidth: "100%",
                "&:hover": {
                  opacity: 0.3,
                },
                maxHeight: 550,
                minHeight: 300,
              }}
              className={secondary ? "img-slide-sec" : "img-slide"}
            /> */}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0.5}
              className="titleContentTestimonialSlide"
            >
              <div className="bgText">
                {i.comment && (
                  <Grid item xs={12} xl={7} md={11} lg={12}>
                    <h3 className={"subTitleText"}>{i.comment}</h3>
                  </Grid>
                )}
                {i.client_name && (
                  <Grid item xs={12} xl={7} md={11} lg={12}>
                    <h4 className={"titleText"}>
                      <span> - {i.client_name} - </span>
                    </h4>
                  </Grid>
                )}
              </div>
              <Grid
                item
                xs={12}
                xl={7}
                md={11}
                lg={12}
                alignItems="center"
                justify="center"
              >
                <Avatar
                  className={classes.largeAvatar}
                  src={`${imageUrl}comment/${i.photo}`}
                />
              </Grid>
            </Grid>
          </div>
        ))}
    </Carousel>
  );
}

TestimonialSlide.defaultProp = {
  items: [],
  height: 500,
  setWidth: "100%",
  centerMode: false,
  fixed: false,
  secondary: false,
  imageBg: require("assets/img/bg-testimonial.jpg").default,
};

TestimonialSlide.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      images: PropTypes.string.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  height: PropTypes.number.isRequired,
  centerMode: PropTypes.bool.isRequired,
  setWidth: PropTypes.number.isRequired,
  secondary: PropTypes.bool.isRequired,
  imageBg: PropTypes.string.isRequired,
};

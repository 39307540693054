import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import HomePage from "views/HomePage";
import AboutPage from "views/AboutPage";
import AreaPage from "views/AreaPage";
import CommentPage from "views/CommentPage";
import ContactPage from "views/ContactPage";
import { ThemeProvider } from "@material-ui/styles";
import theme from "theme";
import "i18n";
import { Suspense } from "react";
import MyDocumentPage from "views/MyDocumentPage";
import ExamPage from "views/ExamPage";
import BlogDetailPage from "views/BlogDetailPage";
import LegalNoticePage from "views/LegalNoticePage";
import PrivacyPolicyPage from "views/PrivacyPolicyPage";
import GeneralTermPage from "views/GeneralTermPage";
import { LinearProgress } from "@material-ui/core";
import AreaContentPage from "views/AreaContentPage";
import ExamResultPage from "views/ExamResultPage";
import SearchResultPage from "views/SearchResultPage";
import SearchContentPage from "views/SearchContentPage";

var hist = createBrowserHistory();

export default function App() {
  return (
    <Suspense fallback={<LinearProgress color="secondary" />}>
      <Router history={hist}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/landing-page" component={LandingPage} />
            <Route path="/profile-page" component={ProfilePage} />
            <Route path="/mydocument-page" component={MyDocumentPage} />
            <Route path="/exam-page" component={ExamPage} />
            <Route path="/exam-result" component={ExamResultPage} />
            <Route path="/login-page" component={LoginPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/component" component={Components} />
            <Route path="/areas" component={AreaPage} />
            <Route
              path="/area-content/:struct/:id/:subgroup"
              component={AreaContentPage}
            />
            <Route path="/comments" component={CommentPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/blog/:slug" component={BlogDetailPage} />
            <Route path="/legal-notice" component={LegalNoticePage} />
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route path="/general-term" component={GeneralTermPage} />
            <Route exact path="/" component={HomePage} />
            <Route path="/search/:term" component={SearchResultPage} />
            <Route path="/content" component={SearchContentPage} />
          </Switch>
        </ThemeProvider>
      </Router>
    </Suspense>
  );
}

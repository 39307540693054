import { combineReducers } from "redux";

import homeReducer from "redux/reducers/home.reducers";
import slideReducer from "redux/reducers/slides.reducers";
import contactReducer from "redux/reducers/contact.reducers";
import testimonialReducer from "redux/reducers/testimonial.reducers";
import partnerReducer from "redux/reducers/partner.reducers";
import aboutReducer from "redux/reducers/about.reducers";
import areaReducer from "redux/reducers/area.reducers";
import commentReducer from "redux/reducers/comment.reducers";
import authReducer from "redux/reducers/auth.reducers";
import userReducer from "redux/reducers/user.reducers";
import saveUserReducer from "redux/reducers/saveUser.reducers";
import examReducer from "redux/reducers/exam.reducers";
import blogReducer from "redux/reducers/blog.reducers";
import legalNoticeReducer from "redux/reducers/legalNotice.reducers";
import privacyPolicyReducer from "redux/reducers/privacyPolicy.reducers";
import generalTermReducer from "redux/reducers/generalTerm.reducers";
import graphReducer from "redux/reducers/graph.reducers";
import searchReducer from "redux/reducers/search.reducers";

export default combineReducers({
  searchReducer,
  graphReducer,
  generalTermReducer,
  privacyPolicyReducer,
  legalNoticeReducer,
  blogReducer,
  examReducer,
  saveUserReducer,
  userReducer,
  authReducer,
  homeReducer,
  slideReducer,
  contactReducer,
  testimonialReducer,
  partnerReducer,
  aboutReducer,
  areaReducer,
  commentReducer,
});

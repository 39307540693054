/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import { AccountCircle } from "@material-ui/icons";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth.actions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.userReducer);

  const renderMenu = ({ role }) => {
    if (role === "student_role") {
      return (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={user && user.profile.name}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={AccountCircle}
            dropdownList={[
              <Link to="/exam-page" className={classes.dropdownLink}>
                EXAM
              </Link>,
              <Button
                size="small"
                className={classes.dropdownLink}
                onClick={() => {
                  dispatch(logout(history));
                }}
              >
                LOGOUT
              </Button>,
            ]}
          />
        </ListItem>
      );
    } else if (role === "customer_role") {
      return (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={user && user.profile.name}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={AccountCircle}
            dropdownList={[
              <Link to="/profile-page" className={classes.dropdownLink}>
                PROFILE
              </Link>,
              <Link to="/mydocument-page" className={classes.dropdownLink}>
                MY DOCUMENT
              </Link>,
              <Button
                size="small"
                className={classes.dropdownLink}
                onClick={() => {
                  dispatch(logout(history));
                }}
              >
                LOGOUT
              </Button>,
            ]}
          />
        </ListItem>
      );
    }
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink color="transparent" to="/" className={classes.navLink}>
          {t("MENU_HOME")}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          color="transparent"
          to="/about"
          className={classes.navLink}
          activeClassName={classes.navLinkActive}
        >
          {t("MENU_ABOUT")}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          color="transparent"
          to="/areas"
          className={classes.navLink}
          activeClassName={classes.navLinkActive}
        >
          {t("MENU_AREA")}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          color="transparent"
          to={`/comments`}
          className={classes.navLink}
          activeClassName={classes.navLinkActive}
        >
          {t("MENU_COMMENT")}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          color="transparent"
          to="/contact"
          className={classes.navLink}
          activeClassName={classes.navLinkActive}
        >
          {t("MENU_CONTACT")}
        </NavLink>
      </ListItem>
      {!localStorage.getItem("@token") && (
        <ListItem className={classes.listItem}>
          <Link
            color="transparent"
            to="/login-page"
            className={classes.navLink}
            activeClassName={classes.navLinkActive}
          >
            {t("MENU_LOGIN")}
          </Link>
        </ListItem>
      )}
      {localStorage.getItem("@token") && user ? renderMenu(user) : null}
    </List>
  );
}

import { httpClient } from "utils/HttpClient";

export const fullSearch = (term) => {
  return (dispatch) => {
    dispatch({
      type: "SEARCH_FETCHING",
    });

    return httpClient
      .get(`/frontend/full-search/${term}`)
      .then(({ data }) => {
        dispatch({
          type: "SEARCH_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "SEARCH_REJECTED" }));
  };
};

const initialState = {
  tokenData: null,
  itemId: null,
  isLoading: false,
  isRejected: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_ACCESS_TOKEN_FETCHING":
      return {
        ...state,
        tokenData: null,
        itemId: null,
        isLoading: true,
        isRejected: false,
      };
    case "GET_ACCESS_TOKEN_SUCCESS":
      return {
        ...state,
        tokenData: payload.access_token,
        itemId: payload.itemId,
        isLoading: false,
        isRejected: false,
      };
    case "GET_ACCESS_TOKEN_REJECTED":
      return {
        ...state,
        isLoading: false,
        isRejected: true,
      };
    case "GET_DOCUMENT_ITEM_FETCHING":
      return {
        ...state,
        itemId: null,
        isLoading: true,
        isRejected: false,
      };
    case "GET_DOCUMENT_ITEM_SUCCESS":
      return {
        ...state,
        itemId: payload,
        isLoading: false,
        isRejected: false,
      };
    case "GET_DOCUMENT_ITEM_REJECTED":
      return {
        ...state,
        isLoading: false,
        isRejected: true,
      };
    default:
      return state;
  }
};

import { Container, LinearProgress, makeStyles } from "@material-ui/core";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Layouts from "components/Layouts";
import React, { useEffect } from "react";
import styles from "assets/jss/material-kit-react/views/homePage.js";
import Footer from "components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralTerm } from "redux/actions/generalTerm.actions";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function GeneralTermPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const dispatch = useDispatch();
  const { generalTerm } = useSelector((state) => state.generalTermReducer);

  useEffect(() => {
    dispatch(getGeneralTerm());
    return () => {};
  }, []);

  if (generalTerm.isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts
      title={"General Term"}
      tag={
        generalTerm.data && generalTerm.data.meta && generalTerm.data.meta.tags
      }
    >
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 500,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="xl" disableGutters>
        <div className={classNames(classes.main, classes.defaultBoxShadow)}>
          <div
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingBottom: 30,
            }}
            dangerouslySetInnerHTML={{
              __html: generalTerm.data && generalTerm.data.general_term.content,
            }}
          />
        </div>
      </Container>
      <Footer />
    </Layouts>
  );
}

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Card from "components/Card/Card";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Layouts from "components/Layouts";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-kit-react/views/mydocumentPage.js";
// import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getExam } from "redux/actions/exam.actions";
import axios from "axios";
import { httpClient } from "utils/HttpClient";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Check from "@material-ui/icons/Check";
import Result from "./Result";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ExamPage(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);

  const [answerSingleChoice, setAnswerSingleChoice] = useState({
    exam_id: null,
    exam_choice_id: null,
    user_id: null,
    make_correct: false,
  });

  const [completeExam, setCompleteExam] = useState(false);

  const [answerMultiChoice, setAnswerMultiChoice] = useState([]);

  const [answerWritting, setAnswerWritting] = useState({
    exam_id: null,
    exam_choice_id: null,
    details: "",
    user_id: null,
  });

  const [resultExam, setResultExam] = useState([]);

  const {
    exams: { data, isLoading },
  } = useSelector((state) => state.examReducer);

  useEffect(() => {
    dispatch(getExam());
    return () => {};
  }, []);

  const nextChioce = () => {
    dispatch({ type: "EXAM_FETCHING" });
    axios
      .get(data.next_page_url + "&token=" + localStorage.getItem("@token"))
      .then(({ data }) => {
        dispatch({ type: "EXAM_SUCCESS", payload: data });
      })
      .catch(() => {
        dispatch({ type: "EXAM_REJECTED" });
      });
  };

  const renderChoice = ({ id, type, exam_choices }) => {
    if (type === "single-choice") {
      return (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={answerSingleChoice.exam_choice_id}
            onChange={(e) => {
              setAnswerSingleChoice({
                exam_id: id,
                exam_choice_id: parseInt(e.target.value),
                user_id: user.profile.id,
              });
            }}
          >
            {exam_choices.map((i) => (
              <FormControlLabel
                key={i.id}
                value={i.id}
                control={<Radio />}
                label={i.description}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    } else if (type === "multiple-choice") {
      return (
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}
        >
          <FormGroup>
            {exam_choices.map((i) => (
              <FormControlLabel
                key={i.id}
                control={
                  <Checkbox
                    onChange={(e) => {
                      var index = answerMultiChoice.findIndex(
                        (x) => x.exam_choice_id === e.target.value
                      );
                      if (index === -1) {
                        setAnswerMultiChoice((prevState) => [
                          ...prevState,
                          {
                            exam_id: id,
                            exam_choice_id: e.target.value,
                            user_id: user.profile.id,
                          },
                        ]);
                      } else {
                        const newValues = answerMultiChoice.slice();
                        newValues.splice(index, 1);
                        setAnswerMultiChoice(newValues);
                      }
                    }}
                    name={i.description}
                  />
                }
                value={i.id}
                label={i.description}
              />
            ))}
          </FormGroup>
        </FormControl>
      );
    } else if (type === "writing") {
      return (
        <Box>
          <TextField
            id="outlined-multiline-static"
            multiline
            label={exam_choices[0].description}
            rows={4}
            fullWidth
            onChange={(e) => {
              setAnswerWritting({
                exam_id: id,
                exam_choice_id: exam_choices[0].id,
                details: e.target.value,
                user_id: user.profile.id,
              });
            }}
            variant="outlined"
          />
        </Box>
      );
    }
  };
  const [progressBarValue, setProgressBarValue] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      intervalRef.current = setInterval(() => {
        setProgressBarValue((prev) => {
          if (prev >= 100) {
            clearInterval(intervalRef.current);
            return 100;
          } else {
            return prev + 5;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isLoading]);

  const renderContent = () => {
    if (completeExam) {
      return <Result data={resultExam} />;
    } else {
      return (
        <>
          <CardHeader>
            <Typography component="h1" variant="h4" align="center">
              Exam ({data && data.current_page}/{data && data.total})
            </Typography>
          </CardHeader>
          <CardBody>
            <Box display="flex" flexDirection="column" m={2}>
              <Box p={1}>
                {data.data && (
                  <Typography key={data.data[0].id} variant="h6">
                    {data && data.current_page}. {data.data[0].question}
                  </Typography>
                )}
                <Divider />
                {data.data && renderChoice(data.data[0])}
              </Box>
              {/* <Box p={1}>
              <Typography component="h4" variant="h5" align="right">
                Total : {data && data.total}
              </Typography>
              <Typography component="h4" variant="h5" align="right">
                Current : {data && data.current_page}
              </Typography>
            </Box> */}
              <Box justifyContent="center" textAlign="right" p={1}>
                <Divider />
                <br />
                <Button
                  variant="contained"
                  onClick={() => {
                    if (data.data[0].type === "single-choice") {
                      httpClient
                        .post(
                          `/student/exams?token=${localStorage.getItem(
                            "@token"
                          )}&type=${data.data[0].type}`,
                          answerSingleChoice
                        )
                        .then((result) => {
                          if (result.data.length) {
                            let resultData = result.data;
                            setResultExam((prevState) => [
                              ...prevState,
                              ...resultData,
                            ]);
                          }

                          if (data.next_page_url) {
                            nextChioce();
                          }
                          if (data.total === data.current_page) {
                            history.push("/exam-result");
                            dispatch(getExam());
                          }
                        });
                    } else if (data.data[0].type === "multiple-choice") {
                      httpClient
                        .post(
                          `/student/exams?token=${localStorage.getItem(
                            "@token"
                          )}&type=${data.data[0].type}`,
                          {
                            answers: answerMultiChoice,
                          }
                        )
                        .then((result) => {
                          if (result.data.length) {
                            let resultData = result.data;
                            setResultExam((prevState) => [
                              ...prevState,
                              ...resultData,
                            ]);
                          }
                          if (data.next_page_url) {
                            nextChioce();
                          }
                          if (data.total == data.current_page) {
                            history.push("/exam-result");
                            setCompleteExam(true);
                            dispatch(getExam());
                          }
                        });
                    } else if (data.data[0].type === "writing") {
                      httpClient
                        .post(
                          `/student/exams?token=${localStorage.getItem(
                            "@token"
                          )}&type=${data.data[0].type}`,
                          answerWritting
                        )
                        .then((result) => {
                          if (result.data.length) {
                            let resultData = result.data;
                            setResultExam((prevState) => [
                              ...prevState,
                              ...resultData,
                            ]);
                          }
                          if (data.next_page_url) {
                            nextChioce();
                          }
                          if (data.total == data.current_page) {
                            history.push("/exam-result");
                            setCompleteExam(true);
                            dispatch(getExam());
                          }
                        });
                    }
                  }}
                >
                  {data.total !== data.current_page ? "Next" : "Complete"}
                </Button>
              </Box>
            </Box>
          </CardBody>
        </>
      );
    }
  };

  return (
    <Layouts title="Exam">
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 500,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="md" style={{ marginTop: 150 }}>
        <Card plain className={classes.container}>
          <div className={classNames(classes.main, classes.defaultBoxShadow)}>
            <Paper className={classes.paper}>
              {isLoading ? (
                <CustomLinearProgress
                  variant="determinate"
                  color="info"
                  value={progressBarValue}
                />
              ) : (
                <>
                  {data.data && data.data.length ? (
                    renderContent()
                  ) : (
                    <SnackbarContent
                      message={
                        <span>
                          <b>Not</b> have the exam.
                        </span>
                      }
                      close
                      color="warning"
                      icon={Check}
                    />
                  )}
                </>
              )}
            </Paper>
          </div>
        </Card>
      </Container>
      <Clearfix />
    </Layouts>
  );
}

const initialState = {
  blogDetails: {
    data: null,
    isLoading: false,
    isRejected: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "BLOGDETAILS_FETCHING":
      return {
        ...state,
        blogDetails: {
          isLoading: true,
          isRejected: false,
          data: null,
        },
      };
    case "BLOGDETAILS_SUCCESS":
      return {
        ...state,
        blogDetails: {
          isLoading: false,
          isRejected: false,
          data: payload,
        },
      };
    case "BLOGDETAILS_REJECTED":
      return {
        ...state,
        blogDetails: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

const initialState = {
  areaPages: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
  areaContent: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "AREA_FETCHING":
      return {
        ...state,
        areaPages: {
          data: {},
          isLoading: true,
          isRejected: false,
        },
      };
    case "AREA_SUCCESS":
      return {
        ...state,
        areaPages: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "AREA_REJECTED":
      return {
        ...state,
        areaPages: {
          isLoading: false,
          isRejected: true,
        },
      };
    case "AREACONTENT_FETCHING":
      return {
        ...state,
        areaContent: {
          data: {},
          isLoading: true,
          isRejected: false,
        },
      };
    case "AREACONTENT_SUCCESS":
      return {
        ...state,
        areaContent: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "AREACONTENT_REJECTED":
      return {
        ...state,
        areaContent: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

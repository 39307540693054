import {
  Breadcrumbs,
  Container,
  Divider,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Layouts from "components/Layouts";
import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/aboutPage.js";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "components/Footer/Footer";
import { getAreaContent } from "redux/actions/area.action";
import { useTranslation } from "react-i18next";
import { primaryColor } from "assets/jss/material-kit-react";

const useStyles = makeStyles(styles);
export default function AreaContentPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const {
    areaContent: { data, isLoading },
  } = useSelector((state) => state.areaReducer);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAreaContent(params.struct, params.id, params.subgroup));

    return () => {};
  }, [params]);

  if (isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts
      title={data.areacontent && data.areacontent.name}
      tag={data.areacontent && data.areacontent.name}
    >
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="lg">
        {data.areacontent && (
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/areas">
              <a
                style={{
                  color: primaryColor,
                }}
              >
                {t("MENU_AREA")}
              </a>
            </Link>
            <Typography color="textPrimary">
              {data.areacontent && data.areacontent.name}
            </Typography>
          </Breadcrumbs>
        )}
        <Divider />
        <div
          className={classNames(classes.main, classes.defaultBoxShadow)}
          style={{ padding: 50 }}
        >
          {data.areacontent && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<h3>${
                  data.areacontent && data.areacontent.name
                }</h3><hr>`,
              }}
            />
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: data.areacontent
                ? data.areacontent.content
                : "<h3>Data not found</h3>",
            }}
          />
        </div>
      </Container>
      <Footer />
    </Layouts>
  );
}

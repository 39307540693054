import React, { useEffect } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homePage.js";
import Footer from "components/Footer/Footer";
import {
  Container,
  LinearProgress,
  Typography,
  Divider,
  // Box,
  Paper,
  Grid,
} from "@material-ui/core";
import Layouts from "components/Layouts";
import { useTranslation } from "react-i18next";
import Slide from "components/Slide/Slide";
import SectionCarousel from "views/HomePage/Sections/SectionCarousel";
import { useSelector, useDispatch } from "react-redux";
import TestimonialSlide from "components/Slide/TestimonialSlide";
import { getAllHome } from "redux/actions/home.actions";
import MutipleSlider from "components/MutipleSlider";
import ReactTypingEffect from "react-typing-effect";
import { useHistory } from "react-router";
import "./HomePage.css";

const useStyles = makeStyles(styles);

const HomePage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { homes } = useSelector((state) => state.homeReducer);

  useEffect(() => {
    dispatch(getAllHome());
    return () => {};
  }, []);

  if (homes.isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts
      title={t("MENU_HOME")}
      tag={homes.data.meta && homes.data.meta.tags}
    >
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 500,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="xl" disableGutters>
        <div style={{ marginTop: -20 }}>
          <Slide items={homes.data.slides} />
        </div>
        {homes.data.hotNews && (
          <div className={classes.section}>
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Paper
                style={{
                  padding: 2,
                  width: "100%",
                  textAlign: "center",
                  margin: 30,
                }}
              >
                <Grid container xs={12} sm={12}>
                  <Grid
                    item
                    lg={3}
                    xs={12}
                    sm={12}
                    style={{
                      backgroundColor: "rgb(33, 46, 60)",
                      color: "white",
                      margin: "auto",
                    }}
                  >
                    <h4 className="textTitle">{t("TITLE_BREAK_NEWS")}</h4>
                  </Grid>
                  <Grid item lg={9} xs={12} sm={12} zeroMinWidth>
                    {homes.data.hotNews && (
                      <ReactTypingEffect
                        text={homes.data.hotNews.map((n) => n.content)}
                        cursorRenderer={(cursor) => <h4>{cursor}</h4>}
                        eraseSpeed={25}
                        speed={80}
                        displayTextRenderer={(text, i) => {
                          return (
                            <div key={i}>
                              <a
                                style={{
                                  color: "black",
                                }}
                                onClick={() => {
                                  const { slug } = homes.data.hotNews[i];
                                  history.push(`/blog/${slug}`);
                                }}
                              >
                                <h4 className="textEff">{text}</h4>
                              </a>
                            </div>
                          );
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
        )}
        <div className={classes.section}>
          {homes.data.secondSlides && (
            <MutipleSlider items={homes.data.secondSlides} />
          )}
          <div className={classes.section}>
            <div
              style={{
                position: "relative",
                textAlign: "center",
              }}
            >
              <img
                src={require("assets/img/bg-home.jpg").default}
                style={{
                  width: "80%",
                  maxHeight: 580,
                  alignItems: "center",
                }}
              />
              <div className={classes.textCaptionSection2} />
              <Typography
                variant="h4"
                component="h2"
                noWrap={true}
                className={classes.textCaptionSection}
              >
                {t("HOME_TITLE_CAPTION")}
              </Typography>
            </div>
          </div>
          <div className={classes.section}>
            <Typography
              variant="h3"
              component="h2"
              className={classes.textTitleSection}
            >
              {t("HOME_CLIENT_COMMENT")}
            </Typography>
            <Divider />
            <br />
          </div>
          <TestimonialSlide
            height={400}
            items={homes.data.testimonials}
            imageBg={require("assets/img/bg-testimonial.jpg").default}
          />
          <div className={classes.container}>
            <br />
            <br />
            <Typography
              variant="h3"
              component="h2"
              className={classes.textTitleSection}
            >
              {t("HOME_PARTNERS_MEMBERS")}
            </Typography>
            <Divider />
            <br />
            <SectionCarousel slidesToShow={3} data={homes.data.partners} />
          </div>
          <br />
          <br />
        </div>
      </Container>
      <Footer />
    </Layouts>
  );
};

export default HomePage;

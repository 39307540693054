import React, { useEffect, useRef, useState } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import styles from "assets/jss/material-kit-react/views/contactPage.js";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax";
import Layouts from "components/Layouts";
// import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import {
  Box,
  FormControl,
  FormHelperText,
  Button,
  Typography,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import InfoContact from "components/InfoContact/InfoContact.js";
import BusinessIcon from "@material-ui/icons/Business";
import { Formik, Form, Field } from "formik";
import { TextField, Checkbox } from "formik-material-ui";
import { Link } from "react-router-dom";
import { primaryColor } from "assets/jss/material-kit-react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { httpClient } from "utils/HttpClient";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { Check } from "@material-ui/icons";
import { values } from "lodash-es";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

const useStyles = makeStyles({
  ...styles,
  button: {
    backgroundColor: primaryColor,
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgb(133,240,210)",
    },
  },
  alink: {
    color: primaryColor,
    "&:hover": {
      color: "rgb(133,240,210)",
    },
  },
});

const ContactPage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();
  const [alertShow, setAlertShow] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const {
    contact: { data },
  } = useSelector((state) => state.contactReducer);
  const intervalRef = useRef(null);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  useEffect(() => {
    if (isLoadingForm) {
      intervalRef.current = setInterval(() => {
        setProgressBarValue((prev) => {
          if (prev >= 100) {
            clearInterval(intervalRef.current);
            return 100;
          } else {
            return prev + 5;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isLoadingForm]);

  if (!data) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts title={"Contact"} tag={data.meta && data.meta.tags}>
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div style={{ marginTop: -20, marginBottom: 20 }}>
        <Parallax image={require("assets/img/contact-bg.jpg").default} small />
      </div>
      <div className={classNames(classes.main)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <div className={classNames(classes.textCenter, classes.section)}>
              <Typography variant="h3">{t("CONTACT_PAGE_TITLE")}</Typography>
            </div>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              <Box
                p={5}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                alignItems="center"
              >
                <InfoContact title={t("FOOTER_TITLE1")} icon={BusinessIcon}>
                  <Muted>
                    {data.contactDe && data.contactDe.address1}{" "}
                    {data.contactDe && data.contactDe.address2}
                  </Muted>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-number.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactDe && data.contactDe.hotline1}</Muted>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-phone.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactDe && data.contactDe.hotline2}</Muted>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-fax.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactDe && data.contactDe.fax}</Muted>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-email.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactDe && data.contactDe.email}</Muted>
                  </div>
                </InfoContact>
              </Box>
              <Box p={5}>
                <InfoContact title={t("FOOTER_TITLE2")} icon={BusinessIcon}>
                  <Muted>
                    {data.contactTh && data.contactTh.address1}{" "}
                    {data.contactTh && data.contactTh.address2}
                  </Muted>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-number.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactTh && data.contactTh.hotline1}</Muted>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-phone.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactTh && data.contactTh.hotline2}</Muted>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-fax.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactTh && data.contactTh.fax}</Muted>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      padding: 5,
                    }}
                  >
                    <img
                      width="24px"
                      src={require("assets/img/icon-email.svg").default}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Muted>{data.contactTh && data.contactTh.email}</Muted>
                  </div>
                </InfoContact>
              </Box>
            </Box>
            <div className={classes.section}>
              <div className={classes.container}>
                <Divider />
                <br />
                <br />
                <Typography variant="h3" align="center">
                  {t("CONTACT_PAGE_TITLE2")}
                </Typography>
                {isLoadingForm && (
                  <CustomLinearProgress
                    variant="determinate"
                    color="info"
                    value={progressBarValue}
                  />
                )}
                {alertShow && (
                  <SnackbarContent
                    message={
                      <span>
                        <b>Thank you for contacting us!</b>
                      </span>
                    }
                    close
                    color="success"
                    icon={Check}
                  />
                )}
                <Formik
                  enableReinitialize
                  initialValues={{
                    contact_name: "",
                    email: "",
                    message_text: "",
                    agreement: false,
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setIsLoadingForm(true);
                    setSubmitting(true);
                    httpClient
                      .post(`/frontend/contact`, values)
                      .then(({ data }) => {
                        if (data) {
                          setAlertShow(true);
                          setIsLoadingForm(false);
                        }
                      })
                      .catch((err) => {
                        alert(err.message);
                        setIsLoadingForm(false);
                      });
                    setSubmitting(false);
                    resetForm({});
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.contact_name) {
                      errors.contact_name = "Required";
                    }
                    if (!values.email) {
                      errors.email = "Required";
                    }
                    if (!values.message_text) {
                      errors.message_text = "Required";
                    }
                    if (!values.agreement) {
                      errors.agreement = "Required";
                    }
                    return errors;
                  }}
                  render={({
                    submitForm,
                    isSubmitting,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form noValidate>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-around"
                        m={5}
                        flexWrap="wrap"
                      >
                        <Box p={2}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              errors.contact_name &&
                                touched.contact_name &&
                                errors.contact_name
                            )}
                            variant="outlined"
                          >
                            <Field
                              component={TextField}
                              name="contact_name"
                              label={t("CONTACT_FORM_NAME")}
                              error={Boolean(
                                errors.contact_name && errors.contact_name
                              )}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                        <Box p={2}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              errors.email && touched.email && errors.email
                            )}
                            variant="outlined"
                          >
                            <Field
                              component={TextField}
                              name="email"
                              label={t("CONTACT_FORM_EMAIL")}
                              error={Boolean(errors.email && errors.email)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                        <Box p={2}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              errors.message_text &&
                                touched.message_text &&
                                errors.message_text
                            )}
                          >
                            <Field
                              component={TextField}
                              name="message_text"
                              label={t("CONTACT_FORM_COMMENT")}
                              multiline
                              style={{
                                width: "100%",
                              }}
                              rows={4}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                        <Box p={2}>
                          <FormControl fullWidth>
                            <Box
                              display="flex"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              <Box>
                                <Field
                                  component={Checkbox}
                                  name="agreement"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  checked={values.agreement}
                                  value={values.agreement}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "agreement",
                                      e.target.checked
                                    );
                                  }}
                                />
                                {t("CONTACT_FORM_POLICY")}{" "}
                                <Link to="/general-term">
                                  <a className={classes.alink}>
                                    {t("CONTACT_FORM_POLICY2")}
                                  </a>
                                </Link>
                                {" & "}
                                <Link to="/privacy-policy">
                                  <a className={classes.alink}>
                                    {t("CONTACT_FORM_POLICY3")}{" "}
                                  </a>
                                </Link>
                                {t("CONTACT_FORM_POLICY4")}
                              </Box>
                            </Box>
                            <FormHelperText
                              error={Boolean(
                                errors.agreement &&
                                  touched.agreement &&
                                  errors.agreement
                              )}
                            >
                              {errors.agreement}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                        <Box p={2} display="flex" justifyContent="center">
                          <Button
                            onClick={submitForm}
                            disabled={isSubmitting}
                            variant="outlined"
                            size="large"
                            className={classes.buttonForm}
                          >
                            {t("BUTTON_SEND_TITLE")}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layouts>
  );
};

export default ContactPage;

/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Layouts = forwardRef(
  ({ children, title = "", tag = "", ...rest }, ref) => {
    const { t } = useTranslation();

    return (
      <div ref={ref} {...rest}>
        <Helmet htmlAttributes={{ lang: localStorage.getItem("language") }}>
          <meta charSet="utf-8" />
          <meta name="description" content={tag} />
          <title>{title}</title>
        </Helmet>
        {children}
        <CookieConsent
          location="bottom"
          buttonText={t("COOKIE_LINK_TEXT")}
          cookieName="karst-accept"
          style={{
            // background: "rgba(33,46,60,0.45)",
            background: "rgba(133, 240, 210, 0.45)",
            bottom: 0,
            zIndex: 1000,
            // color: "RGB(133, 240, 210)",
            color: "rgb(33,46,60)",
          }}
          buttonStyle={{
            backgroundColor: "Transparent",
            fontSize: "16px",
            fontWidth: "bold",
            // color: "RGB(133, 240, 210)",
            color: "rgb(33,46,60)",
            borderWidth: 2,
            marginRight: 100,
          }}
          expires={150}
        >
          This website uses cookies to enhance your browsing experience. By
          continuing to explore the website, you consent to the use of cookies
          in accordance with the office’s{" "}
          <Link to="">
            <a
              style={{
                // color: "RGB(133, 240, 210)",
                color: "rgb(33,46,60)",
                textDecoration: "underline",
              }}
            >
              Privacy Statement.
            </a>
          </Link>
        </CookieConsent>
      </div>
    );
  }
);

Layouts.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  tag: PropTypes.string,
};

export default Layouts;

import { container } from "assets/jss/material-kit-react.js";

const componentsStyle = (theme) => ({
  container,
  brand: {
    color: "#FFFFFF",
    textAlign: "left",
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
  },
  slideHeader: {
    marginBottom: 90,
    margin: "0px 30px 0px",
  },
  textCaptionSection: {
    // color: "rgb(133,240,210)",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "Oranienbaum",
    backgroundImage:
      "linear-gradient(90deg, rgba(122,15,45,1) 50%, rgba(255,255,255,1) 100%)",
    padding: 10,
    position: "absolute",
    // top: "-8%",
    top: 0,
    left: "30%",
    width: "40%",
    transform: "translate(-50%, -50%)",
    fontSize: 40,
    // textShadow: "2px 2px grey",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  textCaptionSection2: {
    fontWeight: "bold",
    fontFamily: "Oranienbaum",
    backgroundImage:
      "linear-gradient(90deg, rgba(122,15,45,0.9026961126247374) 0%, rgba(255,255,255,0) 100%)",
    padding: 10,
    position: "absolute",
    // top: "-8%",
    top: 20,
    left: "10%",
    minHeight: 40,
    width: "30%",
  },
  textTitleSection: {
    textAlign: "center",
  },
  section: {
    marginTop: 90,
  },
});

export default componentsStyle;

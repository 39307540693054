import { Container, LinearProgress, makeStyles } from "@material-ui/core";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Layouts from "components/Layouts";
import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/aboutPage.js";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogDetail } from "redux/actions/blog.action";
import Parallax from "components/Parallax/Parallax";
import { imageUrl } from "Constants";
import Footer from "components/Footer/Footer";
import "./BlogDetailPage.css";

const useStyles = makeStyles(styles);
export default function BlogDetailPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const {
    blogDetails: { data, isLoading },
  } = useSelector((state) => state.blogReducer);

  useEffect(() => {
    dispatch(getBlogDetail(params.slug));
    return () => {};
  }, [params.slug]);

  if (isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts title={data && data.content} tag={data && data.tag}>
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="lg">
        {data && (
          <div style={{ marginTop: -20 }}>
            <Parallax
              image={`${imageUrl}/second_slide/${data.background}`}
              small
            />
          </div>
        )}
        <div
          className={classNames(classes.main, classes.defaultBoxShadow)}
          style={{ padding: 50 }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `<h3>${data && data.content}</h3><hr>`,
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: data && data.detail }} />
        </div>
      </Container>
      <Footer />
    </Layouts>
  );
}

import { httpClient } from "utils/HttpClient";

export const login = (value, history) => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_FETCHING" });
    httpClient
      .post(`/auth/login`, value)
      .then(({ data }) => {
        localStorage.setItem("@token", data.access_token);
        dispatch({ type: "LOGIN_SUCCESS" });
        history.push("/");
        window.location.reload();
      })
      .catch(() => {
        dispatch({ type: "LOGIN_FAILED" });
      });
  };
};

export const getProfile = () => {
  return (dispatch) => {
    dispatch({ type: "USER_FETCHING" });
    httpClient
      .post(`/auth/me?token=${localStorage.getItem("@token")}`)
      .then(({ data }) => {
        dispatch({ type: "USER_SUCCESS", payload: data });
      })
      .catch(() => {
        dispatch({ type: "USER_REJECTED" });
      });
  };
};

export const getRole = () => {
  return (dispatch) => {
    dispatch({ type: "ROLE_FETCHING" });
    httpClient
      .post(`/auth/me?token=${localStorage.getItem("@token")}`)
      .then(({ data }) => {
        dispatch({ type: "ROLE_SUCCESS", payload: data });
      })
      .catch(() => {
        dispatch({ type: "ROLE_REJECTED" });
      });
  };
};

export const logout = (history) => {
  return (dispatch) => {
    localStorage.removeItem("@token");
    dispatch({ type: "LOGOUT" });
    history.replace("/");
  };
};

export const getAccessToken = () => {
  return (dispatch) => {
    dispatch({ type: "GET_ACCESS_TOKEN_FETCHING" });
    httpClient
      .get(`/onedrive/graph-token?token=${localStorage.getItem("@token")}`)
      .then(({ data }) => {
        dispatch({
          type: "GET_ACCESS_TOKEN_SUCCESS",
          payload: data,
        });
      })
      .catch(() => {
        dispatch({ type: "GET_ACCESS_TOKEN_REJECTED" });
      });
  };
};

import React, { useEffect } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import styles from "assets/jss/material-kit-react/views/aboutPage.js";
import Card from "components/Card/Card";
// import Primary from "components/Typography/Primary";
import classNames from "classnames";
import Layouts from "components/Layouts";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import White from "components/Typography/White";
// import Muted from "components/Typography/Muted";
import { primaryColor } from "assets/jss/material-kit-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAboutPages } from "redux/actions/about.action";
const useStyles = makeStyles(styles);

const AboutPage = (props) => {
  const dispatch = useDispatch();

  const {
    aboutPages: { data, isLoading },
  } = useSelector((state) => state.aboutReducer);

  useEffect(() => {
    dispatch(getAboutPages());
    return () => {};
  }, []);

  useEffect(() => {
    if (data.question) {
      setExpanded(data.question[0].id);
    }
    return () => {};
  }, [data.question]);

  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts title={t("MENU_ABOUT")} tag={data.meta && data.meta.tags}>
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Card plain>
        <div className={classNames(classes.main, classes.defaultBoxShadow)}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box mt={5}>
              <Typography className={classes.pageTitle} variant="h3">
                FAQ
              </Typography>
            </Box>
            <Box alignItems="center" mb={5}>
              <Typography className={classes.pageTitle} variant="h3">
                {t("ABOUT_PAGE_TITLE")}
              </Typography>
            </Box>
          </Box>
          <Box ml={5} mr={5}>
            {data.question &&
              data.question.map((i) => (
                <Accordion
                  key={i.id}
                  square
                  expanded={expanded === i.id}
                  onChange={handleChange(i.id)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    style={{
                      backgroundColor: primaryColor,
                      fontFamily: "Oranienbaum",
                    }}
                  >
                    <White>
                      <h4 className={classes.textCollepse}>Q: {i.question}</h4>
                    </White>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="html">{i.answers}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </div>
      </Card>
      <Footer />
    </Layouts>
  );
};

export default AboutPage;

import { httpClient } from "utils/HttpClient";

export const getContact = () => {
  return (dispatch) => {
    dispatch({
      type: "CONTACT_FETCHING",
    });

    return httpClient
      .get(`/frontend/contact`)
      .then(({ data }) => {
        dispatch({
          type: "CONTACT_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "CONTACT_REJECTED" }));
  };
};

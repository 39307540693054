import { httpClient } from "utils/HttpClient";

export const getExam = () => {
  return (dispatch) => {
    dispatch({ type: "EXAM_FETCHING" });
    httpClient
      .get(`/student/exams?token=${localStorage.getItem("@token")}`)
      .then(({ data }) => {
        dispatch({ type: "EXAM_SUCCESS", payload: data });
      })
      .catch(() => {
        dispatch({ type: "EXAM_REJECTED" });
      });
  };
};

export const getExamResult = () => {
  return (dispatch) => {
    dispatch({ type: "EXAM_RESULT_FETCHING" });
    httpClient
      .get(`/student/exam-results?token=${localStorage.getItem("@token")}`)
      .then(({ data }) => {
        dispatch({ type: "EXAM_RESULT_SUCCESS", payload: data });
      })
      .catch(() => {
        dispatch({ type: "EXAM_RESULT_REJECTED" });
      });
  };
};

export const getExamScore = () => {
  return (dispatch) => {
    dispatch({ type: "EXAM_SCORE_FETCHING" });
    httpClient
      .get(`/student/exam-score?token=${localStorage.getItem("@token")}`)
      .then(({ data }) => {
        dispatch({ type: "EXAM_SCORE_SUCCESS", payload: data });
      })
      .catch(() => {
        dispatch({ type: "EXAM_SCORE_REJECTED" });
      });
  };
};

const initialState = {
  aboutPages: {
    data: {},
    isLoading: false,
    isRejected: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "ABOUT_FETCHING":
      return {
        ...state,
        aboutPages: {
          data: {},
          isLoading: true,
          isRejected: false,
        },
      };
    case "ABOUT_SUCCESS":
      return {
        ...state,
        aboutPages: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "ABOUT_REJECTED":
      return {
        ...state,
        aboutPages: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

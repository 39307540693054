import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import PropTypes from "prop-types";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);

export default function SectionCarousel({ data, slidesToShow, titleSize }) {
  const classes = useStyles();
  const history = useHistory();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                {data &&
                  data.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => {
                        if (item.link) {
                          history.push(item.link);
                        } else {
                          history.push("/");
                        }
                      }}
                    >
                      <img
                        src={item.images}
                        alt={item.title}
                        style={{
                          // objectFit: "cover",
                          // alignItems: "center",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "50%",
                        }}
                        className="slick-image"
                      />
                      <div className="slick-caption">
                        <Typography variant={titleSize}>
                          {item.title}
                        </Typography>
                        {item.subtitle && <span>{item.subtitle}</span>}
                      </div>
                    </div>
                  ))}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

SectionCarousel.defaultProp = {
  titleSize: "h3",
  data: [],
};

SectionCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      images: PropTypes.string.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  slidesToShow: PropTypes.number.isRequired,
  titleSize: PropTypes.oneOf(["h2", "h3", "h4", "h5", "h6", "span"]),
};

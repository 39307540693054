/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import {
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  Typography,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { primaryColor } from "assets/jss/material-kit-react";
import { useTranslation } from "react-i18next";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useDispatch, useSelector } from "react-redux";
import "./Header.css";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();

  const history = useHistory();

  const {
    contact: { data },
  } = useSelector((state) => state.contactReducer);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const { i18n } = useTranslation();

  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("language")
  );

  const changeLanguage = (lng) => {
    setSelectedLang(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    window.location.reload();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed || trigger,
  });

  const brandComponent = (
    <img
      className={classes.navLogo}
      src={require("assets/img/logo-r.png").default}
    />
  );

  return (
    <div>
      {!fixed && (
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
          className={classes.headerSection}
          p={2}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link to="/">
              <Box>
                <img
                  src={require("assets/img/logo.png").default}
                  alt="karst"
                  className="logoHeader"
                />
              </Box>
            </Link>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography className={classes.contactText}>
              <img
                width="24px"
                src={require("assets/img/icon-number.svg").default}
                style={{
                  marginRight: 10,
                }}
              />
              {/* <Link className={classes.contactText}>
                <a
                  href={`tel:${
                    data.contact ? data.contact.hotline1 : "+49 6122 9291199"
                  }`}
                  className={classes.contactText}
                >
                  {data.contact ? data.contact.hotline1 : "+49 6122 9291199"}
                </a>
              </Link> */}
              <a
                href={`tel:${
                  data.contact ? data.contact.hotline1 : "+49 6122 9291199"
                }`}
                className={classes.contactText}
              >
                {data.contact ? data.contact.hotline1 : "+49 6122 9291199"}
              </a>
            </Typography>
            <Typography className={classes.contactText}>
              <img
                width="24px"
                src={require("assets/img/icon-phone.svg").default}
                style={{
                  marginRight: 10,
                }}
              />
              {/* <Link
                href={`tel:${
                  data.contact ? data.contact.hotline2 : "4915254234375"
                }`}
                className={classes.contactText}
              >
                <a className={classes.contactText}>
                  {data.contact ? data.contact.hotline2 : "49 152 54234375"}
                </a>
              </Link> */}
              <a
                href={`tel:${
                  data.contact ? data.contact.hotline2 : "4915254234375"
                }`}
                className={classes.contactText}
              >
                {data.contact ? data.contact.hotline2 : "49 152 54234375"}
              </a>
            </Typography>
            <Typography className={classes.contactText}>
              <img
                width="24px"
                src={require("assets/img/icon-email.svg").default}
                style={{
                  marginRight: 10,
                }}
              />
              {/* <Link
                href={`mailto:${
                  data.contact ? data.contact.email : "info@karst.legal"
                }`}
                className={classes.contactText}
              >
                <a className={classes.contactText}>
                  {data.contact ? data.contact.email : "info@karst.legal"}
                </a>
              </Link> */}
              <a
                href={`mailto:${
                  data.contact ? data.contact.email : "info@karst.legal"
                }`}
                className={classes.contactText}
              >
                {data.contact ? data.contact.email : "info@karst.legal"}
              </a>
            </Typography>
            <Typography className={classes.contactText}>
              {data.contact
                ? data.contact.work_on
                : "Mo-Fr : 09.00 AM - 06.00 PM (Admin 24/7)"}
            </Typography>
          </Box>
          <Box>
            <Box>
              <Tooltip
                id="instagram-twitter"
                title="Change Site to German"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="primary"
                  onClick={() => changeLanguage("de")}
                  className={classes.navLinkLang}
                >
                  DE
                </Button>
              </Tooltip>
              <Tooltip
                id="instagram-twitter"
                title="Change Site to German"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="primary"
                  onClick={() => changeLanguage("en")}
                  className={classes.navLinkLang}
                >
                  EN
                </Button>
              </Tooltip>
              <Tooltip
                id="instagram-twitter"
                title="Change Site to German"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="primary"
                  className={classes.navLinkLang}
                  onClick={() => changeLanguage("th")}
                >
                  ไทย
                </Button>
              </Tooltip>
            </Box>
            <Divider />
            <Box
              style={{
                color: primaryColor,
              }}
            >
              <TextField
                fullWidth
                color="secondary"
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(e) => {
                  if (e.charCode === 13 && e.target.value) {
                    history.push(`/search/${e.target.value}`);
                    // q += " site:https://karst.legal";
                    // window.open(
                    //   "http://www.google.com/search?q=" + encodeURIComponent(q)
                    // );
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.container}>
          {leftLinks !== undefined ? brandComponent : null}
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            (fixed || trigger) && brandComponent
          )}
          <Box display="flex" width="100%" justifyContent="center">
            <Hidden smDown implementation="css">
              {rightLinks}
            </Hidden>
          </Box>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <a href="#" className="bandMobileLogo">
                KARST
              </a>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    </div>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  contact: PropTypes.object,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};

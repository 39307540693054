import { httpClient } from "utils/HttpClient";

export const getBlogDetail = (slug) => {
  return (dispatch) => {
    dispatch({
      type: "BLOGDETAILS_FETCHING",
    });

    return httpClient
      .get(`/frontend/blog/${slug}`)
      .then(({ data }) => {
        dispatch({
          type: "BLOGDETAILS_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "BLOGDETAILS_REJECTED" }));
  };
};

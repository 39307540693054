import { httpClient } from "utils/HttpClient";

export const getAboutPages = () => {
  return (dispatch) => {
    dispatch({
      type: "ABOUT_FETCHING",
    });

    return httpClient
      .get(`/frontend/abouts`)
      .then(({ data }) => {
        dispatch({
          type: "ABOUT_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "ABOUT_REJECTED" }));
  };
};

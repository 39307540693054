import { httpClient } from "utils/HttpClient";

export const getAreaPages = () => {
  return (dispatch) => {
    dispatch({
      type: "AREA_FETCHING",
    });

    return httpClient
      .get(`/frontend/areas`)
      .then(({ data }) => {
        dispatch({
          type: "AREA_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "AREA_REJECTED" }));
  };
};

export const getAreaContent = ($struct, $id, $subgroup) => {
  return (dispatch) => {
    dispatch({
      type: "AREACONTENT_FETCHING",
    });

    return httpClient
      .get(`/frontend/area-subcontent/${$struct}/${$id}/${$subgroup}`)
      .then(({ data }) => {
        dispatch({
          type: "AREACONTENT_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "AREACONTENT_REJECTED" }));
  };
};

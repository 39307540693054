import { httpClient } from "utils/HttpClient";

export const getAllHome = () => {
  return (dispatch) => {
    dispatch({
      type: "HOME_FETCHING",
    });
    return httpClient
      .get(`/frontend/home`)
      .then(({ data }) => {
        dispatch({
          type: "HOME_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "HOME_REJECTED" }));
  };
};

import React, { useEffect } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import styles from "assets/jss/material-kit-react/views/areaPage.js";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import GridItem from "components/Grid/GridItem";
import InfoAreaMenu from "components/InfoAreaMenu/InfoAreaMenu.js";
import SendIcon from "@material-ui/icons/Send";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { primaryColor } from "assets/jss/material-kit-react";
import Layouts from "components/Layouts";
import { useTranslation } from "react-i18next";
import SectionCarousel from "views/HomePage/Sections/SectionCarousel";
import { useDispatch, useSelector } from "react-redux";
import { getAreaPages } from "redux/actions/area.action";
import TestimonialSlide from "components/Slide/TestimonialSlide";
import { Link } from "react-router-dom";
import "./AreaPage.css";

const useStyles = makeStyles(styles);

const AreaPage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    areaPages: { data, isLoading },
  } = useSelector((state) => state.areaReducer);

  useEffect(() => {
    dispatch(getAreaPages());
    return () => {};
  }, []);

  if (isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts title={t("MENU_AREA")} tag={data.meta && data.meta.tags}>
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div style={{ marginTop: -20 }}>
        <Parallax image={require("assets/img/bg-area.jpg").default} small />
      </div>
      <div className={classNames(classes.main)}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          mt={5}
          mb={-8}
        >
          <Box>
            <br />
            <br />
            <Typography
              color="inherit"
              variant="h3"
              className={classes.headLinePage}
            >
              {t("AREA_PAGE_TITLE")}
            </Typography>
          </Box>
        </Box>
        <div className={classes.section}>
          <div className={classes.container}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <h4 className="textTitleTable">{t("AREA_TITLE1")}</h4>
                    </TableCell>
                    <TableCell align="center" color="primary">
                      <h4 className="textTitleTable">{t("AREA_TITLE2")}</h4>
                    </TableCell>
                    <TableCell align="center">
                      <h4 className="textTitleTable">{t("AREA_TITLE3")}</h4>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" width={"30%"}>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL11")}</li>
                        </ul>
                      </Typography>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL12")}</li>
                        </ul>
                      </Typography>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL13")}</li>
                        </ul>
                      </Typography>
                    </TableCell>
                    <TableCell align="left" width={"30%"}>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL21")}</li>
                        </ul>
                      </Typography>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL22")}</li>
                        </ul>
                      </Typography>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL23")}</li>
                        </ul>
                      </Typography>
                    </TableCell>
                    <TableCell align="left" width={"30%"}>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL31")}</li>
                        </ul>
                      </Typography>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL32")}</li>
                        </ul>
                      </Typography>
                      <Typography component="html">
                        <ul className="list">
                          <li>{t("AREA_DETAIL33")}</li>
                        </ul>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.textCenter}>
            <Typography variant="h2" component="h2">
              {t("AREA_PAGE_TITLE2")}
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              className={classes.headLinePage}
            >
              {t("AREA_PAGE_TITLE3")}
            </Typography>
          </div>
          <div className={classes.container}>
            <Box
              display="flex"
              flexDirection="colunm"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              {data.areas &&
                data.areas.map((a) => {
                  return (
                    <Box m={2} key={a.id}>
                      <InfoAreaMenu
                        title={a.name}
                        icon={SendIcon}
                        iconColor="primary"
                      >
                        <ul id="tree1">
                          {a.childs &&
                            a.childs.map((c) => (
                              <li key={c.id}>
                                <Link
                                  to={`/area-content/${c.areas_id}/${c.sub_group}/${c.id}`}
                                >
                                  <a
                                    style={{
                                      color: primaryColor,
                                    }}
                                  >
                                    {c.name}
                                  </a>
                                </Link>
                                {c.child_seconds && (
                                  <ul>
                                    {c.child_seconds.map((s) => (
                                      <li key={s.id}>
                                        <Link
                                          to={`/area-content/${c.areas_id}/${c.sub_group}/${c.id}`}
                                        >
                                          <a
                                            style={{
                                              color: primaryColor,
                                            }}
                                          >
                                            {s.name}
                                          </a>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                        </ul>
                      </InfoAreaMenu>
                    </Box>
                  );
                })}
            </Box>
          </div>
        </div>
        <GridItem md={12} className={classes.textCenter}>
          <br />
          <Typography variant="h3" className={classes.headLinePage}>
            {t("HOME_CLIENT_COMMENT")}
          </Typography>
        </GridItem>
        <TestimonialSlide height={400} items={data.testimonials} />
        <GridItem md={12} className={classes.textCenter}>
          <br />
          <br />
          <Typography
            variant="h3"
            component="h2"
            className={classes.headLinePage}
          >
            {t("HOME_PARTNERS_MEMBERS")}
          </Typography>
        </GridItem>
        <br />
        <SectionCarousel slidesToShow={3} data={data.partners} />
        <br />
      </div>
      <Footer />
    </Layouts>
  );
};

export default AreaPage;

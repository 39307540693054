import React, { useEffect, useRef, useState } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import styles from "assets/jss/material-kit-react/views/commentPage.js";
import classNames from "classnames";
import GridItem from "components/Grid/GridItem";
import { Formik, Form, Field } from "formik";
import { TextField, Checkbox } from "formik-material-ui";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  FormHelperText,
  Divider,
  Typography,
  LinearProgress,
  Card,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Layouts from "components/Layouts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TestimonialSlide from "components/Slide/TestimonialSlide";
import { getCommentPages } from "redux/actions/comments.action";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import { Check } from "@material-ui/icons";
import { httpClient } from "utils/HttpClient";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import "./CommentPage.css";

const useStyles = makeStyles({
  ...styles,
});

const CommentPage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    commentPages: { data, isLoading },
  } = useSelector((state) => state.commentReducer);
  const [alertShow, setAlertShow] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const intervalRef = useRef(null);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  useEffect(() => {
    dispatch(getCommentPages());
    return () => {};
  }, []);

  useEffect(() => {
    if (isLoading) {
      intervalRef.current = setInterval(() => {
        setProgressBarValue((prev) => {
          if (prev >= 100) {
            clearInterval(intervalRef.current);
            return 100;
          } else {
            return prev + 5;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isLoadingForm]);

  if (isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts title={t("MENU_COMMENT")} tag={data.meta && data.meta.tags}>
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Card plain>
        <div className={classNames(classes.section)}>
          <GridItem md={12} className={classes.textCenter}>
            <Typography variant="h3">{t("COMMENT_HEADLINE")}</Typography>
            <div
              style={{
                textAlign: "left",
              }}
            >
              <h4 className="subHeadLine">{t("COMMENT_SUB_HEADLINE")}</h4>
            </div>
            <Divider />
          </GridItem>
        </div>
        <div className={classNames(classes.section)}>
          <GridItem md={12} className={classes.textCenter}>
            <Typography variant="h3">{t("COMMENT_PAGE_TITLE")}</Typography>
          </GridItem>
          <TestimonialSlide
            height={450}
            items={data && data.comment1}
            imageBg={require("assets/img/bg-testimonial.jpg").default}
          />
        </div>
        <div className={classNames(classes.section)}>
          <GridItem md={12} className={classes.textCenter}>
            <Typography variant="h3">{t("COMMENT_PAGE_TITLE2")}</Typography>
          </GridItem>
          <TestimonialSlide
            height={450}
            items={data && data.comment2}
            imageBg={require("assets/img/Private.png").default}
          />
        </div>
        <div className={classNames(classes.section)}>
          <GridItem md={12} className={classes.textCenter}>
            <Typography variant="h3">{t("COMMENT_PAGE_TITLE3")}</Typography>
          </GridItem>
          <TestimonialSlide
            height={450}
            items={data && data.comment3}
            imageBg={require("assets/img/Personaltraits.png").default}
          />
        </div>
      </Card>
      <br />
      <div className={classNames(classes.main)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <Typography variant="h3" align="center">
              {t("COMMENT_HEADING_FORM")}
            </Typography>
            {isLoadingForm && (
              <CustomLinearProgress
                variant="determinate"
                color="info"
                value={progressBarValue}
              />
            )}
            {alertShow && (
              <SnackbarContent
                message={
                  <span>
                    <b>Successfully, Thank you.</b>
                  </span>
                }
                close
                color="success"
                icon={Check}
              />
            )}
            <Formik
              enableReinitialize
              initialValues={{
                client_name: "",
                client_type: "",
                comment: "",
                agreement: false,
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setIsLoadingForm(true);
                setSubmitting(true);
                httpClient
                  .post(`/frontend/comment`, values)
                  .then(({ data }) => {
                    if (data) {
                      setAlertShow(true);
                      setIsLoadingForm(false);
                    }
                  })
                  .catch((err) => {
                    alert(err.message);
                    setIsLoadingForm(false);
                  });
                setSubmitting(false);
                resetForm({});
              }}
              validate={(values) => {
                const errors = {};

                if (!values.client_name) {
                  errors.client_name = "Required";
                }

                if (!values.client_type) {
                  errors.client_type = "Required";
                }

                if (!values.comment) {
                  errors.comment = "Required";
                }

                if (!values.agreement) {
                  errors.agreement = "Required";
                }

                return errors;
              }}
              render={({
                submitForm,
                isSubmitting,
                touched,
                errors,
                values,
                setFieldValue,
              }) => (
                <Form noValidate>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    m={5}
                    flexWrap="wrap"
                  >
                    <Box p={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          errors.client_type &&
                            touched.client_type &&
                            errors.client_type
                        )}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          {t("COMMENT_INPUT_TITLE1")}
                        </InputLabel>
                        <Select
                          name={"client_type"}
                          value={values.client_type}
                          onChange={(e) => {
                            setFieldValue("client_type", e.target.value);
                          }}
                          displayEmpty
                          fullWidth
                        >
                          <MenuItem key={0} value={1}>
                            Business
                          </MenuItem>
                          <MenuItem key={0} value={2}>
                            Private
                          </MenuItem>
                          <MenuItem key={0} value={3}>
                            Personal
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box p={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          errors.client_name &&
                            touched.client_name &&
                            errors.client_name
                        )}
                        variant="outlined"
                      >
                        <Field
                          component={TextField}
                          name="client_name"
                          label={t("COMMENT_INPUT_TITLE2")}
                          error={Boolean(
                            errors.client_name && errors.client_name
                          )}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Box>
                    <Box p={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          errors.comment && touched.comment && errors.comment
                        )}
                      >
                        <Field
                          component={TextField}
                          name="comment"
                          label={t("COMMENT_INPUT_TITLE3")}
                          multiline
                          style={{
                            width: "100%",
                          }}
                          rows={4}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Box>
                    <Box p={2}>
                      <FormControl fullWidth>
                        <Box display="flex">
                          <Field
                            component={Checkbox}
                            name="agreement"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => {
                              setFieldValue("agreement", e.target.checked);
                            }}
                            checked={values.agreement}
                            value={values.agreement}
                          />
                          <Box>
                            <div>
                              {t("COMMENT_PAGE_POLICY")}{" "}
                              <Link to="/privacy-policy">
                                <a className={classes.linkStyle} href="#">
                                  {t("COMMENT_PAGE_POLICY2")}
                                </a>
                              </Link>
                            </div>
                          </Box>
                        </Box>
                        <FormHelperText
                          error={Boolean(
                            errors.agreement &&
                              touched.agreement &&
                              errors.agreement
                          )}
                        >
                          {errors.agreement}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center">
                      <Button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        className={classes.buttonForm}
                      >
                        {t("COMMENT_BUTTON_TITLE")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
      <Footer />
    </Layouts>
  );
};

export default CommentPage;

const initialState = {
  slides: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
  secSlides: {
    data: [],
    isLoading: false,
    isRejected: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SLIDE_FETCHING":
      return {
        ...state,
        slides: {
          isLoading: true,
          isRejected: false,
          data: [],
        },
      };
    case "SLIDE_SUCCESS":
      return {
        ...state,
        slides: {
          isLoading: false,
          isRejected: false,
          data: payload,
        },
      };
    case "SLIDE_REJECTED":
      return {
        ...state,
        slides: {
          isLoading: false,
          isRejected: true,
        },
      };
    case "SEC_SLIDE_FETCHING":
      return {
        ...state,
        secSlides: {
          isLoading: true,
          isRejected: false,
          data: [],
        },
      };
    case "SEC_SLIDE_SUCCESS":
      return {
        ...state,
        secSlides: {
          isLoading: false,
          isRejected: false,
          data: payload,
        },
      };
    case "SEC_SLIDE_REJECTED":
      return {
        ...state,
        secSlides: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

import React, { useEffect } from "react";
import "./SearchResultPage.css";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Layouts from "components/Layouts";
// import { useTranslation } from "react-i18next";
// import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
// import styles from "assets/jss/material-kit-react/views/searchResultPage.js";
import { Container, LinearProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fullSearch } from "redux/actions/search.action";
// const useStyles = makeStyles(styles);

export default function SearchResultPage(props) {
  // const classes = useStyles();
  const { ...rest } = props;
  // const { t } = useTranslation();
  const param = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    searchResult: { data, isLoading },
  } = useSelector((state) => state.searchReducer);

  console.log(data);

  useEffect(() => {
    dispatch(fullSearch(param.term));
    return () => {};
  }, [param.term]);

  if (isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Layouts title={`Search Result, ${param.term}`} tag={param.term}>
      <Header
        color="primary"
        brand="KARST Legal & Tax"
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 500,
          color: "white",
        }}
        {...rest}
      />
      <Container maxWidth="lg">
        <h3>Result : {param.term}</h3>
        {data.area &&
          data.area.map((a, index) => (
            <div key={index}>
              <h4 className="titleHeader">{a.name}</h4>
              <p>
                <a
                  className="linkHeader"
                  onClick={() => {
                    history.push(`/area-content/${a.areas_id}/${0}/${0}`);
                  }}
                >
                  [Read More]
                </a>
              </p>
              <hr />
            </div>
          ))}
        {data.sec_slide &&
          data.sec_slide.map((a, index) => (
            <div key={index}>
              <h4 className="titleHeader">{a.content}</h4>
              <a
                className="linkHeader"
                onClick={() => {
                  history.push(`/blog/${a.slug}`);
                }}
              >
                [Read More]
              </a>
              <hr />
            </div>
          ))}
      </Container>
      <Footer />
    </Layouts>
  );
}

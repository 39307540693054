import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, Button, Divider, Typography } from "@material-ui/core";

export default function Result({ data }) {
  let result = _.chain(data)
    .groupBy("question")
    .map((value, key) => ({
      question: key,
      result: value,
    }))
    .value();

  return (
    <div style={{ padding: 10 }}>
      <Typography variant="h5" component="h3" color="primary">
        Congratulations
      </Typography>
      <Divider />
      <Box p={5}>
        <Box>
          {result.map((r) => {
            return (
              <>
                Q: {r.question}
                {r.result.map((e) => (
                  <li key={e.examChoiceDesc}>
                    <b>Answer :</b> {e.examChoiceDesc} is correct.
                  </li>
                ))}
                <Divider />
              </>
            );
          })}
        </Box>
        <Box p={5} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Retake
          </Button>
        </Box>
      </Box>
    </div>
  );
}

Result.propTypes = {
  data: PropTypes.array,
};

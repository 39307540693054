import { httpClient } from "utils/HttpClient";

export const getGeneralTerm = () => {
  return (dispatch) => {
    dispatch({
      type: "GENERALTERM_FETCHING",
    });

    return httpClient
      .get(`/frontend/general-term`)
      .then(({ data }) => {
        dispatch({
          type: "GENERALTERM_SUCCESS",
          payload: data,
        });
      })
      .catch(() => dispatch({ type: "GENERALTERM_REJECTED" }));
  };
};

const initialState = {
  contact: {
    data: {},
    isLoading: false,
    isRejected: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "CONTACT_FETCHING":
      return {
        ...state,
        contact: {
          data: {},
          isLoading: true,
          isRejected: false,
        },
      };
    case "CONTACT_SUCCESS":
      return {
        ...state,
        contact: {
          data: payload,
          isLoading: false,
          isRejected: false,
        },
      };
    case "CONTACT_REJECTED":
      return {
        ...state,
        contact: {
          isLoading: false,
          isRejected: true,
        },
      };
    default:
      return state;
  }
};

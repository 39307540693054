/*eslint-disable*/
import React, { useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { Box, List, ListItem, Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import BusinessIcon from "@material-ui/icons/Business";
import TwitterIcon from "@material-ui/icons/Twitter";
import White from "components/Typography/White";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "redux/actions/contact.action";

const useStyles = makeStyles({
  ...styles,
});

export default function Footer(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    contact: { data },
  } = useSelector((state) => state.contactReducer);

  useEffect(() => {
    dispatch(getContact());
    return () => {};
  }, []);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  const { t } = useTranslation();

  return (
    <footer className={footerClasses}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={3} xs={12}>
          <Typography variant="h6" align="center" gutterBottom>
            {t("FOOTER_NAVIGATION")}
          </Typography>
          <List className={classes.list}>
            <ListItem>
              <Link to="/" className={classes.linkViewMap}>
                {t("MENU_HOME")}
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/about" className={classes.linkViewMap}>
                {t("MENU_ABOUT")}
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/areas" className={classes.linkViewMap}>
                {t("MENU_AREA")}
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/comments" className={classes.linkViewMap}>
                {t("MENU_COMMENT")}
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/contact" className={classes.linkViewMap}>
                {t("MENU_CONTACT")}
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/contact"
                className={classes.linkViewMap}
                style={{
                  textDecoration: "none",
                }}
              >
                {t("MENU_LOGIN")}
              </Link>
            </ListItem>
          </List>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Typography variant="h6" align="center" gutterBottom>
            {t("FOOTER_TITLE1")}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          ></Typography>
          <Grid item sm={12} xs={12}>
            <iframe
              width="100%"
              height="150"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://www.openstreetmap.org/export/embed.html?bbox=8.36789131164551%2C50.06491488202179%2C8.371539115905763%2C50.06684665974163&amp;layer=mapnik&amp;marker=50.065880780607%2C8.369715213775635"
              className={classes.mapBox}
            ></iframe>
          </Grid>
          <Grid item sm={12} xs={12}>
            <a
              className={classes.linkViewMap}
              style={{
                alignItems: "center",
                color: "white",
                textDecoration: "none",
              }}
              href="https://www.openstreetmap.org/?mlat=50.06588&amp;mlon=8.36972#map=18/50.06588/8.36972"
            >
              {t("FOOTER_BUTTON_MAP")}
            </a>
          </Grid>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Typography variant="h6" align="center" gutterBottom>
            {t("FOOTER_TITLE2")}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          ></Typography>
          <Grid item sm={12} xs={12}>
            <iframe
              width="100%"
              height="150"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://www.openstreetmap.org/export/embed.html?bbox=100.47514736652376%2C13.716002517753697%2C100.47879517078401%2C13.718894849448985&amp;layer=mapnik&amp;marker=13.7172105%2C100.4770023"
              className={classes.mapBox}
            ></iframe>
          </Grid>
          <Grid item sm={12} xs={12}>
            <a
              className={classes.linkViewMap}
              href="https://www.openstreetmap.org/#map=18/13.71745/100.47697"
              style={{
                alignItems: "center",
                color: "white",
                textDecoration: "none",
              }}
            >
              {t("FOOTER_BUTTON_MAP")}
            </a>
          </Grid>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Typography variant="h6" align="center" gutterBottom>
            <BusinessIcon /> {t("FOOTER_WORK_HR")}
          </Typography>
          <White>{data.contact && data.contact.work_on}</White>
          <Grid item sm={12}>
            <Box display="flex" m={2} justifyContent="space-around">
              <Box>
                <a href="https://twitter.com/KanzleiKARST" target="_blank">
                  <TwitterIcon style={{ color: "white" }} />
                </a>
              </Box>
              <Box>
                <a
                  href="https://www.linkedin.com/in/bjoern-karst-69a45996"
                  target="_blank"
                >
                  <LinkedInIcon style={{ color: "white" }} />
                </a>
              </Box>
              <Box>
                <a href="https://www.anwalt.de/" target="_blank">
                  <img
                    src={require("assets/img/logo_footer.png").default}
                    height="25"
                  />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h6" align="center" gutterBottom>
            2021 © KARST. All Rights Reserved |{" "}
            <Link to="/legal-notice" className={classes.linkFooter}>
              {t("LINK_LEGAL_NOTICE")}
            </Link>{" "}
            |{" "}
            <Link to="/privacy-policy" className={classes.linkFooter}>
              {t("LINK_PRIVACY_POLICY")}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
